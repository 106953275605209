import { ArrowRightIcon } from "@heroicons/react/solid";
import { Fade } from "react-awesome-reveal";

export default function ContactCTA() {
  return (
    <div className="relative">
      <div
        className="absolute left-0 right-0 h-1/2 bg-gray-50"
        aria-hidden="true"
      />
      <div className="relative mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
        <Fade triggerOnce>
          <div className="py-10 px-6 bg-white shadow-lg mb-20 rounded-3xl sm:py-16 sm:px-12 lg:py-20 lg:px-20 lg:flex lg:items-center lg:justify-between">
            <div className="lg:w-0 lg:flex-1">
              <h2 className="text-3xl font-extrabold tracking-tight text-111827 font-poppins">
                We are here to help!
              </h2>
              <p className="mt-4 max-w-3xl text-lg text-6F7582 font-inter">
                Our 24x7 customer care, is always at your assistance to ensure
                our users do not face inconveniences at any stage. <br />
                To get our support contact us.
              </p>
            </div>
            <div className="mt-12 sm:max-w-md lg:mt-0 lg:ml-8">
              <a
                href="/contactUs"
                className="inline-flex font-poppins items-center justify-center w-48 px-4 py-2.5 border border-transparent text-lg font-medium rounded-md text-white bg-purple-light"
              >
                Contact Us
                <ArrowRightIcon
                  className="-mr-1 ml-3 h-5 w-5 "
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
