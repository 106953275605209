import { useEffect } from "react";
import HeroSection from "components/Common/HeroSection/HeroSection";
import ServiceList from "components/Services/ServiceList";
import serviceImg from "assets/homepage/experts.png";

function Services() {
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      <HeroSection
        heading="The iCube Advantage"
        subHeading="We possess over 10 years of experience in helping businesses and providing them with comprehensive and beneficial solutions."
        ctaText="Meet Experts"
        ctaLink="#"
        img={serviceImg}
        marginTop="-mt-140px"
      />
      <ServiceList />
    </>
  );
}

export default Services;
