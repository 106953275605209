import { useEffect, useState } from "react";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";
import { Fade } from "react-awesome-reveal";
import img from "assets/homepage/contactUs.png";

export default function ContactUs() {
    const [body, setBody] = useState("");
    const [contact, setContact] = useState({});
    const [error, setError] = useState(false);
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            let str = "";

            if (contact?.message) {
                str = contact?.message + "\n \n \n \n";
            }
            if (contact?.firstName) {
                str =
                    str +
                    "From, \n" +
                    contact?.firstName +
                    " " +
                    (contact?.lastName || "") +
                    "\n";
            }
            if (contact?.email) {
                str = str + "Email: " + contact?.email + "\n";
            }
            if (contact?.phone) {
                str = str + "Phone: " + contact?.phone + "\n";
            }
            setBody(str);
        }

        return () => {
            isMounted = false;
        };
    }, [contact]);

    return (
        <main className="overflow-hidden mb-20">
            {/* Header */}
            <div className="bg-warm-gray-50">
                <div className="pt-24 pb-20 lg:pt-32 lg:pb-24">
                    <Fade triggerOnce>
                        <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
                            <h1 className="text-4xl font-extrabold tracking-tight text-111827 sm:text-5xl font-poppins">
                                Get in touch
                            </h1>
                            <p className="mt-3 text-xl text-6F7582 font-inter max-w-3xl">
                                We are always looking for bright talent to join
                                us. Please go through the listings below to find
                                a position that fits you.
                            </p>
                        </div>
                    </Fade>
                </div>
            </div>

            {/* Contact section */}
            <section
                className="relative bg-transparent"
                aria-labelledby="contact-heading"
            >
                <div className="absolute w-full h-1/2" aria-hidden="true" />
                {/* Decorative dot pattern */}
                <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <svg
                        className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect
                                    x={0}
                                    y={0}
                                    width={4}
                                    height={4}
                                    className="text-gray-200"
                                    fill="currentColor"
                                />
                            </pattern>
                        </defs>
                        <rect
                            width={404}
                            height={384}
                            fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                        />
                    </svg>
                </div>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pb-0 sm:pb-10">
                    <Fade>
                        <div className="relative bg-white sm:rounded-3xl">
                            <h2 id="contact-heading" className="sr-only">
                                Contact us
                            </h2>

                            <div className="grid grid-cols-1 lg:grid-cols-3">
                                {/* Contact information */}
                                <div className="overflow-hidden py-10 px-6 sm:rounded-t-3xl lg:rounded-tr-none lg:rounded-l-3xl bg-purple-dark sm:px-12 xl:p-12">
                                    <img
                                        src={img}
                                        alt=""
                                        className="mb-10 absolute -top-12 max-w-250px"
                                    />
                                    <h3 className="text-xl font-inter font-medium text-white mt-32">
                                        Contact information
                                    </h3>
                                    <p className="mt-9 text-lg text-white font-normal font-inter max-w-3xl">
                                        Address: 1910 S Stapley Dr #221, Mesa,
                                        AZ 85204
                                    </p>
                                    <dl className="mt-8 space-y-7">
                                        <dt>
                                            <span className="sr-only">
                                                Phone number
                                            </span>
                                        </dt>
                                        <dd className="flex text-lg font-normal font-inter text-white">
                                            <PhoneIcon
                                                className="flex-shrink-0 w-6 h-6 text-white"
                                                aria-hidden="true"
                                            />
                                            <span className="ml-10">
                                                +1 (610)-762-0323
                                            </span>
                                        </dd>
                                        <dt>
                                            <span className="sr-only">
                                                Email
                                            </span>
                                        </dt>
                                        <dd className="flex text-lg font-normal font-inter text-white">
                                            <MailIcon
                                                className="flex-shrink-0 w-6 h-6 text-white"
                                                aria-hidden="true"
                                            />
                                            <span className="ml-10">
                                                hr@icubetechnologyinc.com
                                            </span>
                                        </dd>
                                    </dl>
                                </div>

                                {/* Contact form */}
                                <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                                    <h3 className="text-lg font-medium text-warm-gray-900">
                                        Send us a message
                                    </h3>
                                    <form
                                        action="#"
                                        method="POST"
                                        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                                    >
                                        <div>
                                            <label
                                                htmlFor="first-name"
                                                className="block text-sm font-medium text-warm-gray-900"
                                            >
                                                First name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    required
                                                    value={contact?.firstName}
                                                    onChange={(e) =>
                                                        setContact({
                                                            ...contact,
                                                            firstName:
                                                                e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    name="first-name"
                                                    id="first-name"
                                                    autoComplete="given-name"
                                                    className={`py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-purple-light focus:border-purple-light border-warm-gray-300 rounded-md ${
                                                        !contact?.firstName &&
                                                        error &&
                                                        "border-red-600 border"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="last-name"
                                                className="block text-sm font-medium text-warm-gray-900"
                                            >
                                                Last name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    value={contact?.lastName}
                                                    onChange={(e) =>
                                                        setContact({
                                                            ...contact,
                                                            lastName:
                                                                e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    name="last-name"
                                                    id="last-name"
                                                    autoComplete="family-name"
                                                    className={`py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-purple-light focus:border-purple-light border-warm-gray-300 rounded-md ${
                                                        !contact?.lastName &&
                                                        error &&
                                                        "border-red-600 border"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-warm-gray-900"
                                            >
                                                Email
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    required
                                                    value={contact?.email}
                                                    onChange={(e) =>
                                                        setContact({
                                                            ...contact,
                                                            email: e.target
                                                                .value,
                                                        })
                                                    }
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    className={`py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-purple-light focus:border-purple-light border-warm-gray-300 rounded-md ${
                                                        !contact?.email &&
                                                        error &&
                                                        "border-red-600 border"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex justify-between">
                                                <label
                                                    htmlFor="phone"
                                                    className="block text-sm font-medium text-warm-gray-900"
                                                >
                                                    Phone
                                                </label>
                                                <span
                                                    id="phone-optional"
                                                    className="text-sm text-6F7582"
                                                >
                                                    Optional
                                                </span>
                                            </div>
                                            <div className="mt-1">
                                                <input
                                                    value={contact?.phone}
                                                    onChange={(e) =>
                                                        setContact({
                                                            ...contact,
                                                            phone: e.target
                                                                .value,
                                                        })
                                                    }
                                                    type="number"
                                                    name="phone"
                                                    id="phone"
                                                    autoComplete="tel"
                                                    className={`py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-purple-light focus:border-purple-light border-warm-gray-300 rounded-md`}
                                                    aria-describedby="phone-optional"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label
                                                htmlFor="subject"
                                                className="block text-sm font-medium text-warm-gray-900"
                                            >
                                                Subject
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    required
                                                    value={contact?.subject}
                                                    onChange={(e) =>
                                                        setContact({
                                                            ...contact,
                                                            subject:
                                                                e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    className={`py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-purple-light focus:border-purple-light border-warm-gray-300 rounded-md ${
                                                        !contact?.subject &&
                                                        error &&
                                                        "border-red-600 border"
                                                    }`}
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <div className="flex justify-between">
                                                <label
                                                    htmlFor="message"
                                                    className="block text-sm font-medium text-warm-gray-900"
                                                >
                                                    Message
                                                </label>
                                                <span
                                                    id="message-max"
                                                    className="text-sm text-warm-gray-500"
                                                >
                                                    Max. 500 characters
                                                </span>
                                            </div>
                                            <div className="mt-1">
                                                <textarea
                                                    required
                                                    value={contact?.message}
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value
                                                                ?.length > 500
                                                        )
                                                            return;
                                                        setContact({
                                                            ...contact,
                                                            message:
                                                                e.target.value,
                                                        });
                                                    }}
                                                    id="message"
                                                    name="message"
                                                    rows={4}
                                                    className={`py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-purple-light focus:border-purple-light border border-warm-gray-300 rounded-md ${
                                                        !contact?.message &&
                                                        error &&
                                                        "border-red-600"
                                                    }`}
                                                    aria-describedby="message-max"
                                                    defaultValue={""}
                                                />
                                            </div>
                                        </div>

                                        <a
                                            onClick={() => {
                                                if (
                                                    !contact?.subject ||
                                                    !contact?.firstName ||
                                                    !contact?.email ||
                                                    !contact?.message
                                                )
                                                    setError(true);
                                            }}
                                            href={
                                                !contact?.subject ||
                                                !contact?.firstName ||
                                                !contact?.email ||
                                                !contact?.message
                                                    ? null
                                                    : `mailto:hr@icubetechnologyinc.com?subject=${contact?.subject}&body=${body}`
                                            }
                                        >
                                            <div className="sm:col-span-2 sm:flex sm:justify-end">
                                                <button
                                                    style={{
                                                        boxShadow:
                                                            "0px 8px 16px rgba(0, 0, 0, 0.2)",
                                                    }}
                                                    type="button"
                                                    className="inline-flex font-poppins items-center justify-center w-36 md:w-44 px-3 py-2 border border-transparent text-lg md:text-xl font-bold rounded-md text-white bg-purple-light"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
        </main>
    );
}
