export default function HeroSection({
  heading,
  subHeading,
  ctaText,
  ctaLink,
  img,
  marginTop,
}) {
  return (
    <div className="relative pt-32 pb-12 sm:pt-40 sm:pb-28 bg-gray-50">
      <div
        className="max-w-7xl sm:mx-4 xl:mx-auto lg:px-8 bg-purple-dark sm:rounded-3xl"
        style={{ boxShadow: "0px 12px 24px rgba(80, 52, 92, 0.3)" }}
      >
        <div className="flex flex-col md:flex-row items-start w-full">
          <div className="w-full md:w-1/2 relative">
            <div
              className={`max-w-250px sm:max-w-sm mx-auto px-4 md:max-w-3xl sm:px-6 lg:max-w-none lg:p-0 w-full ${marginTop}`}
            >
              <div className="mx-auto">
                <img
                  className="object-cover object-center rounded-3xl mx-auto"
                  src={img}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 pb-14 pt-6 sm:py-14">
            <div className="relative max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6">
              <h2
                className="text-4xl font-extrabold text-white"
                id="join-heading"
              >
                {heading}
              </h2>

              <p className="text-xl text-white font-normal font-inter mt-3">
                {subHeading}
              </p>

              {ctaText && (
                <div className="mt-8 md:mt-12 max-w-sm sm:max-w-none sm:flex sm:justify-start">
                  <div className="sm:max-w-md">
                    <a
                      style={{ boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)" }}
                      href={ctaLink}
                      className="inline-flex font-poppins items-center justify-center w-48 md:w-64 px-4 py-3 border border-transparent text-lg md:text-3xl font-bold rounded-md text-white bg-purple-light"
                    >
                      {ctaText}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
