import Home from "pages/Home";
import AboutUs from "pages/AboutUs";
import Services from "pages/Services";
import ContactUs from "pages/ContactUs";
import Careers from "pages/Careers";
import Navigation from "components/Common/Navigation";

export const navigation = [
    {
        name: "Home",
        path: "/",
        component: (
            <Navigation>
                <Home />
            </Navigation>
        ),
    },
    {
        name: "About Us",
        path: "/aboutUs",
        component: (
            <Navigation>
                <AboutUs />
            </Navigation>
        ),
    },
    {
        name: "Services",
        path: "/services",
        component: (
            <Navigation>
                <Services />
            </Navigation>
        ),
    },
    {
        name: "Contact Us",
        path: "/contactUs",
        component: (
            <Navigation>
                <ContactUs />
            </Navigation>
        ),
    },
    {
        name: "Careers",
        path: "/careers",
        component: (
            <Navigation>
                <Careers />
            </Navigation>
        ),
    },
];
