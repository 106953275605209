import { navigation } from "helpers/Navigation";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer className="bg-purple-dark">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center font-poppins"
          aria-label="Footer"
        >
          {navigation.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link
                to={item.path}
                className="text-base text-fefefe transform transition ease-in-out duration-150 hover:scale-105"
              >
                {item.name}
              </Link>
            </div>
          ))}
        </nav>

        <p className="mt-8 text-center text-base text-fefefe">
          &copy; 2020 , Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
