import { getHeader, Instance } from "../index";

export const getAllJobs = (query = {}) => {
    return Instance.get(
        `/public/jobs${
            Object.keys(query)?.length > 0
                ? `?${new URLSearchParams(query)}`
                : ""
        }`,
        getHeader()
    );
};
