import { TrendingUpIcon } from "@heroicons/react/solid";
import heroImage from "assets/homepage/hero.png";

export default function HeroSection() {
  return (
    <div className="relative bg-gray-50 overflow-hidden">
      <div className="relative md:pt-6 pb-16 sm:pb-24 lg:pb-32">
        <main className="md:mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:mt-32">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8 flex flex-col-reverse">
            <div className="md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
              <h1>
                <span className="mt-1 block text-4xl sm:text-5xl">
                  <span
                    className="block text-0D142E font-poppins font-normal"
                    style={{ lineHeight: 1.22 }}
                  >
                    Innovation and focus needed to help your
                  </span>
                  <span
                    className="block font-bold uppercase "
                    style={{ lineHeight: 1.22 }}
                  >
                    business <span className="text-green">grow</span>
                  </span>
                </span>
              </h1>
              <div className="md:mt-12 flex flex-col md:flex-row items-start md:items-center mt-3 sm:mt-5">
                <div className="md:h-11 md:w-11 w-8 h-8 bg-opacity-95 bg-0D142E text-gray-200 rounded-md mr-3 flex flex-row items-center justify-center">
                  <TrendingUpIcon className="w-6 h-6 md:h-7 md:w-7" />
                </div>

                <p className="text-base text-gray-500 md:w-11/12 mt-2 md:mt-0">
                  With over 10 years of experience helping businesses to find
                  comprehensive solutions.
                </p>
              </div>
              <div className="mt-8 md:mt-12 max-w-sm sm:max-w-none sm:flex sm:justify-start">
                <div className=" sm:max-w-md">
                  <a
                    href="/contactUs"
                    style={{
                      boxShadow: "0px 8px 16px rgba(59, 130, 246, 0.25)",
                    }}
                    className="inline-flex font-poppins items-center justify-center w-36 md:w-56 px-4 py-2.5 border border-transparent text-lg md:text-2xl font-bold rounded-md text-white bg-purple-light"
                  >
                    Get a Quote
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
              <div className="relative mx-auto w-full rounded-lg lg:-mt-28 lg:max-w-md">
                <div className="relative block w-full overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent">
                  <img
                    className="w-full h-96 object-contain md:h-auto"
                    src={heroImage}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
