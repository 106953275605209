import {
  BadgeCheckIcon,
  CodeIcon,
  LightBulbIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import { Fade } from "react-awesome-reveal";

const features = [
  {
    name: "Commitment",
    description:
      "For us, Customer satisfaction is the fuel to work better and keep providing the best service. We make sure to cater to our customers’ every need rather than measuring our success based on mere figures alone.",
    icon: BadgeCheckIcon,
  },
  {
    name: "In depth industry and government knowledge",
    description:
      "Complete industry and real world knowledge which enables us to predict problems that could cause issues and nip those issues in the bud before they cause any inconvenience to your project.",
    icon: LightBulbIcon,
  },
  {
    name: "Extremely skilled staff with experience, that excel in handling latest upto-date softwares and cutting edge technology.",
    description: "",
    icon: CodeIcon,
  },
  {
    name: "A Rich experience and superb skill set for large as well as small scale project management ranging across domains.",
    description: "",
    icon: SparklesIcon,
  },
];

export default function Advantage() {
  return (
    <div className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <Fade triggerOnce>
          <div className="lg:text-center">
            <p className="text-3xl leading-8 font-poppins font-extrabold tracking-tight text-111827sm:text-4xl">
              The iCube Advantage
            </p>
            <p className="mt-4 max-w-2xl text-xl text-111827 lg:mx-auto font-inter">
              We have our team of experienced experts working on client
              requirements.
              <br /> One stop destination for a plethora of services.
              <br />
              Timely delivery of product to client along with excellent customer
              care support that is reachable at all times.
            </p>
          </div>
        </Fade>
        <Fade triggerOnce>
          <div className="mt-14">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-10 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-purple-light text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-111827 font-poppins">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-6F7582 font-inter">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </Fade>
      </div>
    </div>
  );
}
