import { useEffect } from "react";
import Features from "components/Home/Features";
import HeroSection from "components/Home/HeroSection";
import Advantage from "components/Home/Advantage";
import ContactCTA from "components/Home/ContactCTA";

function Home() {
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      <HeroSection />
      <Features />
      <Advantage />
      <ContactCTA />
    </>
  );
}

export default Home;
