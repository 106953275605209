import { useEffect } from "react";
import HeroSection from "components/Common/HeroSection/HeroSection";
import CoreValues from "components/AboutUs/CoreValues";
import aboutImg from "assets/homepage/Mission.png";

function AboutUs() {
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      <HeroSection
        heading="Our Mission"
        subHeading="To solve clients’ problems with innovative and interdisciplinary solutions and approaches by integrating various sources bridging business and technology"
        ctaText="Contact Us"
        ctaLink="/contactUs"
        img={aboutImg}
        marginTop="-mt-112px"
      />
      <CoreValues />
    </>
  );
}

export default AboutUs;
