import { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import OpeningContent from "./OpeningContent";

function Openings({ item }) {
    return (
        <>
            <Disclosure
                as="div"
                className="w-full bg-white shadow rounded-lg py-7 px-5 md:px-7 lg:pr-16 lg:pl-44"
            >
                {({ open }) => (
                    <>
                        <div className="w-full flex flex-row items-center justify-between">
                            <h1 className="font-bold text-111827 text-lg sm:text-3xl leading-5 md:leading-8">
                                {item?.title}
                            </h1>
                            <div className="flex flex-row items-center">
                                <Disclosure.Button className="ml-12">
                                    {open ? (
                                        <MinusIcon className="h-6 w-6 text-6F7582" />
                                    ) : (
                                        <PlusIcon className="h-6 w-6 text-6F7582" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>

                        <Transition
                            show={open}
                            enter="transition-opacity duration-75"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Disclosure.Panel
                                static
                                as="div"
                                className="mt-5 flex flex-col items-start space-y-7"
                            >
                                {!item?.numberOfPositions?.hide && (
                                    <p className="font-normal text-lg leading-5 md:leading-6 text-6F7582 font-inter">
                                        <span className="font-semibold">
                                            {item?.numberOfPositions?.label ||
                                                "Positions"}
                                            :{" "}
                                        </span>
                                        {item?.numberOfPositions?.value || ""}
                                    </p>
                                )}
                                {!item?.location?.hide && (
                                    <p className="font-normal text-lg leading-5 md:leading-6 text-6F7582 font-inter">
                                        <span className="font-semibold">
                                            {item?.location?.label ||
                                                "Job Location"}
                                            :{" "}
                                        </span>
                                        {item?.location?.richText ? (
                                            <OpeningContent
                                                value={item?.location?.richText}
                                            />
                                        ) : (
                                            item?.location?.value || ""
                                        )}
                                    </p>
                                )}
                                {!item?.description?.hide && (
                                    <p className="font-normal text-lg leading-5 md:leading-6 text-6F7582 font-inter">
                                        <span className="font-semibold">
                                            {item?.description?.label ||
                                                "Job Description"}
                                            :{" "}
                                        </span>
                                        {item?.description?.richText ? (
                                            <OpeningContent
                                                value={
                                                    item?.description?.richText
                                                }
                                            />
                                        ) : (
                                            item?.description?.value || ""
                                        )}
                                    </p>
                                )}
                                {!item?.duties?.hide && (
                                    <p className="font-normal text-lg leading-5 md:leading-6 text-6F7582 font-inter">
                                        <span className="font-semibold">
                                            {item?.duties?.label ||
                                                "Job Duties"}
                                            :{" "}
                                        </span>
                                        {item?.duties?.richText ? (
                                            <OpeningContent
                                                value={item?.duties?.richText}
                                            />
                                        ) : (
                                            item?.duties?.value || ""
                                        )}
                                    </p>
                                )}
                                {!item?.requirements?.hide && (
                                    <p className="font-normal text-lg leading-5 md:leading-6 text-6F7582 font-inter">
                                        <span className="font-semibold">
                                            {item?.requirements?.label ||
                                                "Job requirements"}
                                            :{" "}
                                        </span>
                                        {item?.requirements?.richText ? (
                                            <OpeningContent
                                                value={
                                                    item?.requirements?.richText
                                                }
                                            />
                                        ) : (
                                            item?.requirements?.value || ""
                                        )}
                                    </p>
                                )}
                                {!item?.miscellaneous?.hide &&
                                    item?.miscellaneous?.richText && (
                                        <p className="font-normal text-lg leading-5 md:leading-6 text-6F7582 font-inter">
                                            <span className="font-semibold">
                                                {item?.miscellaneous?.label}
                                            </span>
                                            {item?.miscellaneous?.richText ? (
                                                <OpeningContent
                                                    value={
                                                        item?.miscellaneous
                                                            ?.richText
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                    )}

                                {!item?.mailResumeTo?.hide &&
                                    item?.mailResumeTo?.value &&
                                    item?.mailResumeTo?.value !== " " && (
                                        <div className="flex flex-row items-center mt-3 text-sm font-normal text-6F7582 font-inter">
                                            {item?.mailResumeTo?.label}
                                            {item?.mailResumeTo?.value}
                                        </div>
                                    )}
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </>
    );
}

export default Openings;
