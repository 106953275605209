// Import React dependencies.
import React, { useMemo, useCallback } from "react";
// Import the Slate editor factory.
import { createEditor } from "slate";

// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from "slate-react";

// Utils
// import withTable from "helpers/utils/SlateJs/withTable";

function OpeningContent({ value }) {
    const editor = useMemo(() => withReact(createEditor()), []);

    // A leaf rendering function that is memoized with `useCallback`.
    const renderLeaf = useCallback((props) => {
        // console.log("attr", props.attributes);
        // console.log("type", props.leaf);
        // console.log("child", props.children);
        if (props.leaf.bold) {
            props.children = <b>{props.children}</b>;
        }

        if (props.leaf.italic) {
            props.children = <em>{props.children}</em>;
        }

        if (props.leaf.underline) {
            props.children = <u>{props.children}</u>;
        }

        return <span {...props.attributes}>{props.children}</span>;
    }, []);

    // An element rendering function that is memoized with `useCallback`.
    const renderElement = useCallback((props) => {
        const element = props.element;
        const children = props.children;
        const attributes = props.attributes;
        const style = { textAlign: element.align };
        // console.log("abc", attributes, children, element);
        switch (element.type) {
            case "block-quote":
                return (
                    <blockquote style={style} {...attributes}>
                        {children}
                    </blockquote>
                );
            case "bulleted-list":
                return (
                    <ul
                        className="pl-4 list-disc"
                        style={style}
                        {...attributes}
                    >
                        {children}
                    </ul>
                );
            case "heading-one":
                return (
                    <h1 style={style} {...attributes}>
                        {children}
                    </h1>
                );
            case "heading-two":
                return (
                    <h2 style={style} {...attributes}>
                        {children}
                    </h2>
                );
            case "list-item":
                return (
                    <li style={style} {...attributes}>
                        {children}
                    </li>
                );
            case "numbered-list":
                console.log("children", children);
                return (
                    <ol
                        className="pl-4 list-decimal"
                        style={style}
                        {...attributes}
                    >
                        {children}
                    </ol>
                );
            case "table": {
                return (
                    <table className="w-full border-black border-[1px] border-solid">
                        <tbody {...attributes}>{children}</tbody>
                    </table>
                );
            }
            case "table-row":
                return <tr {...attributes}>{children}</tr>;
            case "table-cell":
                return (
                    <td
                        {...attributes}
                        className="border-black border-[1px] border-solid px-[5px] h-[50px]"
                    >
                        {children}
                    </td>
                );
            default:
                return (
                    <p style={style} {...attributes}>
                        {children}
                    </p>
                );
        }
    }, []);

    return (
        <>
            <Slate editor={editor} value={value} initialValue={value}>
                <div className="py-6 px-7 h-50vh overflow-y-auto bg-white rounded-b-md">
                    <Editable
                        readOnly
                        placeholder="Enter some plain text..."
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                    />
                </div>
            </Slate>
        </>
    );
}

export default OpeningContent;
