import { Fade } from "react-awesome-reveal";
import img from "assets/homepage/ourApproach.png";

export default function CoreValues() {
  return (
    <div className="relative pt-44 lg:pt-16 pb-32 md:pb-56 bg-gray-50">
      <div className="max-w-7xl sm:mx-4 xl:mx-auto sm:px-4 flex flex-row items-center justify-center">
        <div className="lg:px-10 bg-white sm:rounded-3xl shadow w-full lg:w-11/12">
          <div className="flex flex-col lg:flex-row items-stretch w-full">
            <div className="w-full lg:w-1/2 relative h-24 xs:h-32 sm:h-48">
              <div className="w-full absolute -top-32 lg:top-28 lg:-left-20 xl:-left-28">
                <Fade direction="left">
                  <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
                    <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                      <img
                        className="object-cover object-center rounded-3xl mx-auto"
                        src={img}
                        alt=""
                      />
                    </div>
                  </div>
                </Fade>
              </div>
            </div>

            <div className="w-full lg:w-1/2 pt-10 pb-5 sm:py-20">
              <div
                className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block"
                aria-hidden="true"
              ></div>

              <div className="relative max-w-md mx-auto px-4 space-y-6 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
                <Fade>
                  <h2
                    className="text-3xl font-extrabold text-111827 font-poppins"
                    id="join-heading"
                  >
                    Our Approach
                  </h2>
                </Fade>
                <Fade>
                  <p className="text-lg text-6F7582 font-inter">
                    We are a Program Management and Information Technology
                    consulting firm. Our mission is to solve clients’ problems
                    with innovative and interdisciplinary solutions and
                    approaches by integrating various sources bridging business
                    and technology. Our senior staff and consultants possess
                    about 20 years of professional experience in their area of
                    expertise, having served Forgray 200 companies, government
                    agencies, and non-profit organizations. Our company excels
                    at providing evidence-based and data driven support for
                    e-government and decision making. By integrating data
                    regardless of size or structure, we identify recurring
                    patterns, build metrics and implement integrated analytics
                    systems that provide value, optimization and cost
                    advantages.
                  </p>
                </Fade>
                {/* <a
                className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-blue-700 hover:bg-white sm:inline-block sm:w-auto"
                href="#"
              >
                Explore open positions
              </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
