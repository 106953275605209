import img1 from "assets/homepage/project.png";
import img2 from "assets/homepage/data.png";
import img3 from "assets/homepage/services.png";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

export default function Features() {
    return (
        <div className="py-5 md:py-16 bg-gray-50 overflow-hidden lg:py-24 px-4">
            <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="relative">
                    <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-111827 sm:text-4xl">
                        Our Core Services
                    </h2>
                    <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-111827">
                        We Provide a wide range of services extending from
                        Analytics and Business Intelligence to Data Processing
                        and many more, with the focused aim of making your work
                        easier with our services.
                    </p>
                </div>

                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <Fade triggerOnce direction="left">
                        <div className="relative">
                            <h3 className="text-2xl font-extrabold text-111827 tracking-tight sm:text-3xl">
                                Project Management
                            </h3>
                            <p className="mt-3 text-lg text-6F7582">
                                We possess the resources and the experience
                                needed to achieve the specified project
                                objectives, and employ these skill set to fulfil
                                client requests within stipulated time.
                            </p>
                        </div>
                    </Fade>
                    <div
                        className="mt-10 -mx-4 relative lg:mt-0"
                        aria-hidden="true"
                    >
                        <svg
                            className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                            width={784}
                            height={404}
                            fill="none"
                            viewBox="0 0 784 404"
                        >
                            <defs>
                                <pattern
                                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect
                                        x={0}
                                        y={0}
                                        width={4}
                                        height={4}
                                        className="text-gray-200"
                                        fill="currentColor"
                                    />
                                </pattern>
                            </defs>
                            <rect
                                width={784}
                                height={404}
                                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                            />
                        </svg>
                        <Fade triggerOnce direction="right">
                            <img
                                className="relative mx-auto lg:mr-0 rounded-md"
                                width={490}
                                src={img1}
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>

                <svg
                    className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect
                                x={0}
                                y={0}
                                width={4}
                                height={4}
                                className="text-gray-200"
                                fill="currentColor"
                            />
                        </pattern>
                    </defs>
                    <rect
                        width={404}
                        height={784}
                        fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                    />
                </svg>

                <div className="relative mt-12 sm:mt-16 lg:mt-24">
                    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div className="mt-10 -mx-4 relative lg:mt-0 hidden lg:block">
                            <svg
                                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                                width={784}
                                height={404}
                                fill="none"
                                viewBox="0 0 784 404"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect
                                            x={0}
                                            y={0}
                                            width={4}
                                            height={4}
                                            className="text-gray-200"
                                            fill="currentColor"
                                        />
                                    </pattern>
                                </defs>
                                <rect
                                    width={784}
                                    height={404}
                                    fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                                />
                            </svg>
                            <Fade triggerOnce direction="left">
                                <img
                                    className="relative mx-auto rounded-md lg:ml-0"
                                    width={490}
                                    src={img2}
                                    alt=""
                                />
                            </Fade>
                        </div>
                        <Fade triggerOnce direction="right">
                            <div className="l">
                                <h3 className="text-2xl font-extrabold text-111827 tracking-tight sm:text-3xl">
                                    Data Analytics
                                </h3>
                                <p className="mt-3 text-lg text-6F7582">
                                    We deliver to our clients with advanced
                                    analytical solutions to assist them in
                                    discovering their hidden potential and gain
                                    a better vision of their abilities.
                                </p>
                            </div>
                        </Fade>
                        <div className="mt-10 -mx-4 relative lg:mt-0 lg:hidden block">
                            <svg
                                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                                width={784}
                                height={404}
                                fill="none"
                                viewBox="0 0 784 404"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect
                                            x={0}
                                            y={0}
                                            width={4}
                                            height={4}
                                            className="text-gray-200"
                                            fill="currentColor"
                                        />
                                    </pattern>
                                </defs>
                                <rect
                                    width={784}
                                    height={404}
                                    fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                                />
                            </svg>
                            <Fade triggerOnce direction="right">
                                <img
                                    className="relative mx-auto rounded-md"
                                    width={490}
                                    src={img2}
                                    alt=""
                                />
                            </Fade>
                        </div>
                    </div>
                </div>

                <svg
                    className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect
                                x={0}
                                y={0}
                                width={4}
                                height={4}
                                className="text-gray-200"
                                fill="currentColor"
                            />
                        </pattern>
                    </defs>
                    <rect
                        width={404}
                        height={784}
                        fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                    />
                </svg>

                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <Fade triggerOnce direction="left">
                        <div className="relative">
                            <h3 className="text-2xl font-extrabold text-111827 tracking-tight sm:text-3xl">
                                Managed Services
                            </h3>
                            <p className="mt-3 text-lg text-6F7582">
                                We excel at optimising business processes,
                                increasing productivity rates, increasing
                                operational effectiveness, strategising and
                                transforming in addition to delivering advanced
                                analytics.
                            </p>
                        </div>
                    </Fade>
                    <div
                        className="mt-10 -mx-4 relative lg:mt-0"
                        aria-hidden="true"
                    >
                        <svg
                            className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                            width={784}
                            height={404}
                            fill="none"
                            viewBox="0 0 784 404"
                        >
                            <defs>
                                <pattern
                                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect
                                        x={0}
                                        y={0}
                                        width={4}
                                        height={4}
                                        className="text-gray-200"
                                        fill="currentColor"
                                    />
                                </pattern>
                            </defs>
                            <rect
                                width={784}
                                height={404}
                                fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                            />
                        </svg>
                        <Fade triggerOnce direction="right">
                            <img
                                className="relative mx-auto lg:mr-0 rounded-md"
                                width={490}
                                src={img3}
                                alt=""
                            />
                        </Fade>
                    </div>
                </div>

                <svg
                    className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect
                                x={0}
                                y={0}
                                width={4}
                                height={4}
                                className="text-gray-200"
                                fill="currentColor"
                            />
                        </pattern>
                    </defs>
                    <rect
                        width={404}
                        height={784}
                        fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
                    />
                </svg>
                <div className="relative mt-12 lg:mt-24 w-full mx-auto lg:items-center">
                    <Fade triggerOnce>
                        <div className="w-full flex flex-row items-center justify-center">
                            <Link to="/services">
                                <button className="mt-2 font-poppins inline-flex items-center justify-center w-36 md:w-56 px-4 py-2.5 border border-transparent text-lg md:text-2xl font-bold rounded-md text-white bg-purple-light">
                                    View More
                                </button>
                            </Link>
                        </div>
                    </Fade>
                </div>
            </div>
        </div>
    );
}
