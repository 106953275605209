import { Fragment } from "react";

// Assets
import Logo from "assets/logoSmall.png";
import { XIcon } from "@heroicons/react/solid";

// Utils
import { navigation } from "helpers/Navigation";
import { Link } from "react-router-dom";

export default function MobileNavigation({ setMobileMenuOpen }) {
  return (
    <>
      <div className="py-5 flex items-center justify-between px-7">
        <div className="w-3/4">
          <Link to="/">
            <div
              onClick={() => setMobileMenuOpen(false)}
              className="flex items-center justify-start h-8"
            >
              <img className="h-11 w-auto" src={Logo} alt="Logo" />
              <h1 className="font-extrabold text-xs ml-2 leading-tight text-white">
                iCube <br />
                Technologies
              </h1>
            </div>
          </Link>
        </div>
        <button
          type="button"
          className="-mr-2 inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent"
          onClick={() => setMobileMenuOpen(false)}
        >
          <span className="sr-only">Close main menu</span>
          <XIcon className="block h-8 w-8 text-white" aria-hidden="true" />
        </button>
      </div>

      <div className="max-w-8xl mx-auto mt-12 py-3 px-4 space-y-8">
        {navigation.map((item) => (
          <Fragment key={item.name}>
            <Link to={`${item.path}`}>
              <a
                target={item.newTab ? "_blank" : ""}
                onClick={() => setMobileMenuOpen(false)}
                className="block rounded-md py-2 px-3 font-bold text-white text-4xl"
              >
                {item.name}
              </a>
            </Link>
          </Fragment>
        ))}
      </div>
    </>
  );
}
