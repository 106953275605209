import { useEffect } from "react";
import HeroSection from "components/Common/HeroSection/HeroSection";
import CurrentOpenings from "components/Careers/CurrentOpenings";
import img from "assets/homepage/careerHero.png";

function Careers() {
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }

        return () => {
            isMounted = false;
        };
    }, []);
    return (
        <>
            <HeroSection
                heading="Come Work with Us"
                subHeading="We are always looking for bright talent to join us. Please go through the listings below to find a position that fits you."
                img={img}
                marginTop="-mt-28 sm:-mt-145px"
            />
            {/* <div className="p-4 mb-20 flex flex-row items-center justify-center h-20">
                <h2
                    className="text-4xl font-extrabold text-black"
                    id="join-heading"
                >
                    Coming Soon!
                </h2>
            </div> */}
            <CurrentOpenings />
        </>
    );
}

export default Careers;
