import { Link } from "react-router-dom";

// Utils
import { navigation } from "helpers/Navigation";

function DesktopNavigation() {
    return (
        <nav aria-label="Global" className="flex space-x-3">
            {console.log(window.location.pathname)}
            {navigation.map((nav, index) => {
                if (nav.name == "Contact Us") return;
                return (
                    <Link to={`${nav.path}`} key={index}>
                        <a
                            className={`${
                                window.location.pathname === nav.path
                                    ? "border-2 border-263048CC rounded-lg"
                                    : "border-0"
                            } font-semibold text-sm px-4 py-1 cursor-pointer transform transition hover:scale-105 duration-300 ease-in-out`}
                        >
                            {nav.name}
                        </a>
                    </Link>
                );
            })}
        </nav>
    );
}

export default DesktopNavigation;
