import img1 from "assets/homepage/service1.png";
import img2 from "assets/homepage/service2.png";
import img3 from "assets/homepage/service3.png";
import img4 from "assets/homepage/service4.png";
import img5 from "assets/homepage/service5.png";
import img6 from "assets/homepage/service6.png";
import img7 from "assets/homepage/service7.png";
import img8 from "assets/homepage/service8.png";
import img9 from "assets/homepage/service9.png";
import img10 from "assets/homepage/service10.png";
import { Fade } from "react-awesome-reveal";

const services = [
    {
        name: "Analytics and Business Intelligence",
        imageUrl: img1,
        about: "Equipped with an extensive knowledge of current market trends and upcoming industry practices, we employ to provide analytics for your business to enable you to gain insights about the future of your venture.",
    },
    {
        name: "Data Mining",
        imageUrl: img2,
        about: "We house experts in data mining, that skillfully fulfil client demands, understand, create and test models to deliver them to our clients within a stipulated time period.",
    },
    {
        name: "Data Processing and Hosting",
        imageUrl: img3,
        about: "Providing the most relevant information from several sources then processing it to deliver to our client as per requirements. Furthermore storing this data on our stable and accessible web platform to enable our clients to access their relevant data at their beck and call.",
    },
    {
        name: "Data Warehousing",
        imageUrl: img4,
        about: "Systematic and secure storage of our client data, with complete privacy is what we provide to our customers that is accessible to our client at all times to keep records and gain necessary insights into their operations from time to time. ",
    },
    {
        name: "Web Portals and Content Management Solutions",
        imageUrl: img5,
        about: "We offer a convenient content management system that enables our clients to find an efficient way to manage their digital information that can be deployed on their web portals",
    },
    {
        name: "Data Management",
        imageUrl: img6,
        about: "We strive towards ensuring well ordered management of our clients data as well asperforming data analysis as per requests for future insights.",
    },
    {
        name: "Big Data",
        imageUrl: img7,
        about: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    },
    {
        name: "Enterprise System Integration",
        imageUrl: img8,
        about: "We offer optimised Enterprise System Information that allows seamless and hassle-free sharing of data across all the enterprises.",
    },
    {
        name: "Servers",
        imageUrl: img9,
        about: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    },
    {
        name: "Databases",
        imageUrl: img10,
        about: "Ultricies massa malesuada viverra cras lobortis. Tempor orci hac ligula dapibus mauris sit ut eu. Eget turpis urna maecenas cras. Nisl dictum.",
    },
];

export default function ServiceList() {
    return (
        <div className="bg-gray-50">
            <div className="mx-auto pt-10 pb-20 max-w-7xl lg:py-24">
                <div className="flex flex-col items-start w-full">
                    <Fade triggerOnce>
                        <div className="space-y-5  px-4 sm:px-6 lg:px-8">
                            <h2 className="text-3xl font-extrabold tracking-tight font-poppins text-111827">
                                Services Offering
                            </h2>
                            <p className="text-xl font-inter font-normal text-111827">
                                We provide a wide range of services extending
                                from Analytics and Business Intelligence to Data
                                Processing, with the focussed aim of making your
                                work easier through our services.
                            </p>
                        </div>
                    </Fade>
                    <div className="w-full mt-36 sm:px-6 lg:px-8">
                        <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-32 sm:space-y-0 sm:gap-x-12 brk:gap-x-20">
                            {services.map((service) => (
                                <Fade triggerOnce>
                                    <li
                                        key={service.name}
                                        className="bg-white max-w-md mx-auto md:mx-0 sm:rounded-3xl space-y-6 pb-10 px-11"
                                        style={{
                                            boxShadow:
                                                "0px 12px 24px rgba(0, 0, 0, 0.12)",
                                        }}
                                    >
                                        <div className="w-full h-32 -mt-20">
                                            <img
                                                className="object-cover h-32 rounded-lg"
                                                src={service.imageUrl}
                                                alt=""
                                            />
                                        </div>
                                        <div className="text-xl leading-6 font-medium space-y-1">
                                            <h3>{service.name}</h3>
                                        </div>
                                        {/* <div className="text-lg">
                      <p className="text-gray-500">{service.about}</p>
                    </div> */}
                                    </li>
                                </Fade>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
