import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { navigation } from "helpers/Navigation";

function App() {
    return (
        <React.Fragment>
            <div className="min-h-screen overflow-y-hidden bg-gray-50">
                <Router>
                    <Routes>
                        {navigation?.map((route, idx) => (
                            <Route
                                key={idx}
                                path={route.path}
                                element={route.component}
                            />
                        ))}
                    </Routes>
                </Router>
            </div>
        </React.Fragment>
    );
}

export default App;
