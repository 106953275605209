import { jobs } from "helpers/JobPosting";
import Openings from "./Openings";
import { Fade } from "react-awesome-reveal";
import { useState } from "react";
import { useEffect } from "react";
import { getAllJobs } from "config/APIs/job";

function CurrentOpenings() {
    const [list, setList] = useState([]);

    useEffect(() => {
        getList();
    }, []);

    const getList = async () => {
        try {
            let res = await getAllJobs();
            let data = res?.data?.data?.filter((i) => i?.archived == false);
            setList(data);
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="mx-auto sm:px-7 pt-10 lg:px-8 pb-12 brk:pb-52 max-w-6xl">
            <div className="w-full flex flex-col items-center space-y-7 md:space-y-10">
                {/* <h1
          className={`font-bold text-4xl md:text-5xl leading-snug text-center text-gray-900`}
        >
          Current Openings
        </h1> */}

                <div className="flex flex-col items-center w-full space-y-8 sm:space-y-12">
                    {list?.map((item) => {
                        return (
                            <div className="w-full">
                                <Fade direction="up">
                                    <Openings item={item} />
                                </Fade>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default CurrentOpenings;
